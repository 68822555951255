import React from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
  ContentfulComposePage,
  ContentfulComposeSeo,
  ContentfulPageResourceCategory,
  ResourceCategoryPageQuery,
} from '@/../graphql-types';
import Layout from '@/components/layout/Layout';
import SEO from '@/components/SEO';
import * as Constants from '@/types/Constants';
import PageResourceCategory from '@/components/contentful/pages/ResourceCategory';

export const query = graphql`
  query ResourceCategoryPage(
    $id: String!
    $node_locale: String!
    $today: Date!
  ) {
    contentfulComponentSiteHeader(
      node_locale: { eq: $node_locale }
      name: { eq: "Miovision.com - Site Header" }
    ) {
      ...ContentfulComponentSiteHeaderFragment
    }
    contentfulComponentSiteFooter(
      node_locale: { eq: $node_locale }
      name: { eq: "Miovision.com - Site Footer" }
    ) {
      ...ContentfulComponentSiteFooterFragment
    }
    contentfulComposePage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      internal {
        type
      }
      slug
      title
      seo {
        createdAt
        updatedAt
        description
        id
        keywords
        pageType
        no_follow
        no_index
        title
        canonicalUrl
        featuredImage {
          description
          file {
            url
          }
        }
      }
      content {
        ... on ContentfulPageResourceCategory {
          id
          internal {
            type
          }
          compose__page {
            id
            slug
          }
          topic {
            title
            shortDescription {
              shortDescription
            }
          }
          tabbedResources {
            ...ContentfulComponentTabbedResourcesFragment
          }
          subNav {
            ...ContentfulComponentNavigationFragment
          }
          contactForm {
            ... on ContentfulComponentRegistrationForm {
              ...ContentfulComponentRegistrationFormFragment
            }
          }
          microcopy {
            ... on ContentfulGlobalMicrocopyResource {
              ...ContentfulGlobalMicrocopyResourceFragment
            }
          }
        }
      }
    }
    allContentfulPageCaseStudy(
      filter: {
        node_locale: { eq: $node_locale }
        compose__page: {
          elemMatch: {
            disablePage: { eq: false }
            seo: { no_index: { ne: true } }
          }
        }
      }
      sort: { order: DESC, fields: caseStudy___createdAt }
    ) {
      edges {
        node {
          caseStudy {
            id
            internal {
              type
            }
            title
            subheading
            shortDescription {
              shortDescription
            }
            featureImage {
              gatsbyImageData
            }
            page__case_study {
              compose__page {
                slug
              }
            }
            topic {
              ... on ContentfulTopicProduct {
                id
                name
              }
              ... on ContentfulTopicProductFeature {
                id
                name
              }
              ... on ContentfulTopicProductMarket {
                id
                name
              }
              ... on ContentfulTopicSolution {
                id
                name
              }
            }
          }
        }
      }
    }
    allContentfulPageCustomerStory(
      filter: {
        node_locale: { eq: $node_locale }
        compose__page: { elemMatch: { disablePage: { eq: false } } }
      }
      sort: { order: DESC, fields: customerStory___createdAt }
    ) {
      edges {
        node {
          customerStory {
            id
            internal {
              type
            }
            title
            subheading
            shortDescription {
              shortDescription
            }
            featureImage {
              gatsbyImageData
            }
            page__customer_story {
              compose__page {
                slug
              }
            }
            topic {
              ... on ContentfulTopicProduct {
                id
                name
              }
              ... on ContentfulTopicProductFeature {
                id
                name
              }
              ... on ContentfulTopicProductMarket {
                id
                name
              }
              ... on ContentfulTopicSolution {
                id
                name
              }
            }
          }
        }
      }
    }
    allContentfulPageBlog(
      filter: {
        node_locale: { eq: $node_locale }
        compose__page: { elemMatch: { disablePage: { eq: false } } }
      }
      sort: { order: DESC, fields: blogPost___publicationDate }
    ) {
      edges {
        node {
          blogPost {
            id
            internal {
              type
            }
            title
            shortDescription {
              shortDescription
            }
            featureImage {
              gatsbyImageData
            }
            page__blog {
              compose__page {
                slug
              }
            }
            publicationDate(formatString: "MMM DD, YYYY")
            topic {
              ... on ContentfulTopicProduct {
                id
                name
              }
              ... on ContentfulTopicProductFeature {
                id
                name
              }
              ... on ContentfulTopicProductMarket {
                id
                name
              }
              ... on ContentfulTopicSolution {
                id
                name
              }
            }
          }
        }
      }
    }
    allContentfulPageEvent(
      filter: {
        node_locale: { eq: $node_locale }
        compose__page: { elemMatch: { disablePage: { eq: false } } }
        eventDetails: { endDate: { gte: $today } }
      }
      sort: { order: ASC, fields: eventDetails___startDate }
    ) {
      edges {
        node {
          eventDetails {
            id
            internal {
              type
            }
            title
            subheading
            shortDescription {
              shortDescription
            }
            startDate(formatString: "MMM DD, YYYY")
            endDate(formatString: "MMM DD, YYYY")
            featureImage {
              gatsbyImageData
            }
            page__event {
              compose__page {
                slug
              }
            }
            topic {
              ... on ContentfulTopicProduct {
                id
                name
              }
              ... on ContentfulTopicProductFeature {
                id
                name
              }
              ... on ContentfulTopicProductMarket {
                id
                name
              }
              ... on ContentfulTopicSolution {
                id
                name
              }
            }
          }
        }
      }
    }
    allContentfulPageWebinar(
      filter: {
        node_locale: { eq: $node_locale }
        compose__page: { elemMatch: { disablePage: { eq: false } } }
      }
      sort: {
        fields: [webinar___date, webinar___createdAt]
        order: [ASC, DESC]
      }
    ) {
      edges {
        node {
          webinar {
            id
            internal {
              type
            }
            title
            subheading
            shortDescription {
              shortDescription
            }
            featureImage {
              gatsbyImageData
            }
            page__webinar {
              compose__page {
                slug
              }
            }
            topic {
              ... on ContentfulTopicProduct {
                id
                name
              }
              ... on ContentfulTopicProductFeature {
                id
                name
              }
              ... on ContentfulTopicProductMarket {
                id
                name
              }
              ... on ContentfulTopicSolution {
                id
                name
              }
            }
          }
        }
      }
    }
    allContentfulPagePressRelease(
      filter: {
        node_locale: { eq: $node_locale }
        compose__page: { elemMatch: { disablePage: { eq: false } } }
      }
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          id
          compose__page {
            slug
          }
          title
          excerpt {
            excerpt
          }
          publishDate(formatString: "MMM DD, YYYY")
          internal {
            type
          }
        }
      }
    }
  }
`;

const Page: React.FC<PageProps<ResourceCategoryPageQuery>> = (props) => {
  const { location, data } = props;
  const pageHeader =
    data?.contentfulComposePage?.content?.internal?.type === Constants.PAGE_FLEX
      ? data?.contentfulComposePage?.content?.siteHeader
      : data?.contentfulComponentSiteHeader;
  const pageFooter =
    data?.contentfulComposePage?.content?.internal?.type === Constants.PAGE_FLEX
      ? data?.contentfulComposePage?.content?.siteFooter
      : data?.contentfulComponentSiteFooter;

  let resourceCollection;
  let showTabs = true;
  if (data?.contentfulComposePage?.slug === 'events') {
    resourceCollection = data?.allContentfulPageEvent;
  } else if (data?.contentfulComposePage?.slug === 'case-studies') {
    resourceCollection = data?.allContentfulPageCaseStudy;
    // } else if (data?.contentfulComposePage?.slug === 'guides') {
    //   resourceCollection = data?.allContentfulPageGuides;
  } else if (data?.contentfulComposePage?.slug === 'webinars') {
    resourceCollection = data?.allContentfulPageWebinar;
  } else if (data?.contentfulComposePage?.slug === 'customer-stories') {
    resourceCollection = data?.allContentfulPageCustomerStory;
  } else if (data?.contentfulComposePage?.slug === 'blog') {
    resourceCollection = data?.allContentfulPageBlog;
  } else if (data?.contentfulComposePage?.slug === 'press-releases') {
    resourceCollection = data?.allContentfulPagePressRelease;
    showTabs = false;
  }

  return (
    <>
      <SEO
        location={location}
        defaultPageType={data?.contentfulComposePage?.content?.internal?.type}
        defaultTitle={data?.contentfulComposePage?.title}
        seo={data?.contentfulComposePage?.seo as ContentfulComposeSeo}
      />
      <Layout
        location={location}
        page={data?.contentfulComposePage as ContentfulComposePage}
        header={pageHeader as ContentfulComponentSiteHeader}
        footer={pageFooter as ContentfulComponentSiteFooter}
      >
        <PageResourceCategory
          data={
            data?.contentfulComposePage
              ?.content as ContentfulPageResourceCategory
          }
          showTabs={showTabs}
          resourceCollection={resourceCollection}
        />
      </Layout>
    </>
  );
};

export default Page;
