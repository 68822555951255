import React from 'react';
import styled from 'styled-components';

import { ContentfulPageResourceCategory } from '@/../graphql-types';
import {
  Navigation,
  RegistrationForm,
  ResourceIntro,
  TabbedResources,
} from '@/components/contentful/components';

const Container = styled.div``;

interface PageResourceCategoryProps {
  data?: ContentfulPageResourceCategory;
  resourceCollection?: Record<string, unknown>;
  showTabs?: boolean;
}

const PageResourceCategory: React.FC<PageResourceCategoryProps> = (props) => {
  const { data, resourceCollection, showTabs } = props;

  return (
    <Container className="">
      {data?.subNav && (
        <Navigation
          data={data?.subNav}
          currentPage={data.compose__page[0].id}
        />
      )}
      {data?.topic && <ResourceIntro data={data?.topic} />}
      <TabbedResources
        data={data?.tabbedResources}
        collection={resourceCollection}
        showTabs={showTabs}
      />
      {data?.contactForm && <RegistrationForm data={data?.contactForm} />}
    </Container>
  );
};
export default PageResourceCategory;
